import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

// Components
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import SEO from "../../../components/seo/seo";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import StretchedLinkCards from "../../../components/card/stretched-link-cards";
import FaqAccordion from "../../../components/faq/faq-accordion";
import MortgageCalculator from "../../../components/calculators/mortgage-calculator/calculator";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import VimeoVideoFrame from "../../../components/custom-widgets/vimeo-video-frame";
import useMediaQuery from "../../../hooks/use-media-query";
import ReturningBorrowerLink from "../../../components/home-loans/returning-borrower-link";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import useCardBlogsData from "../../../hooks/use-card-blogs-data";
import getFeaturedCardBlogData from "../../../helpers/getFeaturedCardBlogData";

const HeloanHomeEquityLoan = () => {
  const pageData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(relativePath: { eq: "hero/personal-banking/home-loans/heloan/hero-heloan-111423-XXL.jpg" }) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(relativePath: { eq: "hero/personal-banking/home-loans/heloan/hero-heloan-111423-XL.jpg" }) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(relativePath: { eq: "hero/personal-banking/home-loans/heloan/hero-heloan-111423-LG.jpg" }) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(relativePath: { eq: "hero/personal-banking/home-loans/heloan/hero-heloan-111423-MD.jpg" }) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(relativePath: { eq: "hero/personal-banking/home-loans/heloan/hero-heloan-111423-SM.jpg" }) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(relativePath: { eq: "hero/personal-banking/home-loans/heloan/hero-heloan-111423-XS.jpg" }) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(relativePath: { eq: "hero/personal-banking/home-loans/heloan/hero-heloan-111423-XXS.jpg" }) {
        ...heroChevronImageFragmentXXS
      }
      card01: file(relativePath: { eq: "cards/thumbnail-remodeling-projects-1-05082023-730.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      card02: file(relativePath: { eq: "cards/thumbnail-heloc-student-loans-071223.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      card03: file(relativePath: { eq: "cards/thumbnail-what-is-a-heloan-111423.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  `);

  const title = "Home Equity Loans & HELOAN Calculator";
  const description =
    "Interested in a HELOAN (home equity loan)? Find out how a HELOAN can help you receive a lump sum from your home's equity. Try WaFd Bank's HELOAN calculator today.";

  const seoData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/home-loans/heloan-home-equity-loan"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-heloan-111423.jpg"
      }
    ]
  };

  const heroChevronData = {
    id: "heloan-home-equity-loan-hero",
    ...getHeroImgVariables(pageData),
    altText: "Mother and young son laughing and playing while painting a room.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "When Life Happens, Get a Home Equity Loan"
          }
        },
        {
          id: 2,
          button: {
            id: "hero-cta-btn-1",
            text: "See Today's Rates",
            url: "/personal-banking/home-loans/mortgage-rates",
            class: "btn-white"
          }
        },
        {
          id: 3,
          button: {
            containerClass: "mt-3",
            id: "hero-cta-btn-2",
            class: "btn-light",
            text: "Use Loan Calculator",
            type: "anchor-link",
            url: "#mortgage-calculator-section",
            icon: {
              name: "arrow-down",
              lib: "far",
              class: "float-right mt-1 ml-0 ml-sm-1 ml-md-3"
            }
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      url: "/personal-banking/home-loans",
      title: "Home Loans"
    },
    {
      id: 3,
      active: true,
      title: "Home Equity Loan"
    }
  ];

  const cardsData = useCardBlogsData([
    "/blog/building-remodeling/home-improvements-that-add-value",
    "/blog/home-ownership/home-equity-loan-college-tuition",
    "/blog/home-ownership/what-is-heloan-how-it-works"
  ]);

  const featuredCardsData = getFeaturedCardBlogData(cardsData, { hasText: false });

  const articleCardsData = {
    sectionClass: "pt-0",
    hasGrowEffect: true,
    cards: featuredCardsData.map((card) => ({ ...card, titleClass: "text-green-60" }))
  };

  const faqAccordionData = {
    id: "heloan-faq-accordion",
    seeMoreFaqsLink: "/personal-banking/home-loans/mortgage-faq",
    seeMoreFaqsId: "heloan-see-more-faqs-link",
    faqCategoryNames: ["HELOAN"]
  };

  const AVG_PRICE = 100000;
  const calculatorData = {
    title: "HELOAN",
    loanType: "HELOAN",
    loanTerm: "20 Year Fixed",
    homeValue: 350000,
    interestRate: 0,
    estimatedPayment: 0,
    purchasePrice: AVG_PRICE,
    cashOutAmount: 0,
    loanAmount: AVG_PRICE
  };

  const helocVideoData = {
    vimeoId: "875369659",
    title: "What Can You Use a HELOC For? | WaFd Bank",
    class: "m-auto iframe h-100 w-100 border-radius-12"
  };

  const benefitsOfHomeEquityLineOfCredit = [
    {
      id: 1,
      title:
        '<strong class="text-success">We pay closing costs</strong> for our existing WaFd home loan clients on HELOAN up to $250,000*'
    },
    {
      id: 2,
      title: `<strong class="text-success">Available for owner occupied, second homes, and investment properties</strong>`
    },
    {
      id: 3,
      title: '<strong class="text-success">No annual renewal fees</strong>'
    }
  ];

  const maxTablet = `only screen and (max-width: 992px)`;

  const tabletMediaQueryMatch = useMediaQuery(maxTablet);

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...seoData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />

      <section className="container pb-0">
        <h1>What is a Home Equity Loan (HELOAN)?</h1>
        <h3>
          A HELOAN is a way to use your home equity as collateral, but instead of getting a line of credit, you'll
          receive one lump sum that's repaid over time with a{" "}
          <span className="text-success font-weight-bold">fixed interest rate</span>. It's similar to a home equity line
          of credit (HELOC), but with a HELOC, you have a line of credit you can draw from and pay back, usually with a
          variable interest rate.
        </h3>
        <h3>
          Not sure if a HELOC or a HELOAN is right for you? Find out the{" "}
          <Link
            id="heloan-using-home-equity-heloc-vs-home-equity-loan-link"
            to="/personal-banking/home-loans/using-home-equity-heloc-vs-home-equity-loan"
          >
            difference between a HELOC and a HELOAN
          </Link>{" "}
          to help you decide.
        </h3>
        <ReturningBorrowerLink loanType="HELOAN" />
      </section>

      <MortgageCalculator {...calculatorData} />

      <section className="bg-gray-10">
        <div className="container">
          <div className="row mb-md-4">
            <div className="col-12 col-md-6">
              <div className={`${tabletMediaQueryMatch ? "mb-4" : "mb-0"}`}>
                <VimeoVideoFrame {...helocVideoData} />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <h3>Benefits of a WaFd Home Equity Loan</h3>
              <ul className={`fa-ul`}>
                {benefitsOfHomeEquityLineOfCredit.map((item, index) => (
                  <li key={item.id} className="text-green-90" id={item.id}>
                    <span className="fa-li" style={{ fontSize: "1.2em", lineHeight: "1.25em" }}>
                      <FontAwesomeIcon className="text-green-60 mr-2" icon={["fas", "check-circle"]} />
                    </span>
                    <span dangerouslySetInnerHTML={{ __html: item.title }}></span>
                  </li>
                ))}
              </ul>
              <div id="heloc-closing-cost-disclaimer" className="text-sm">
                * Closing costs are paid only for primary homes, not for second, vacation, or investment properties.
              </div>
            </div>
          </div>
        </div>
      </section>
      <FaqAccordion {...faqAccordionData} />
      <StretchedLinkCards {...articleCardsData} />
    </SecondaryLanding>
  );
};

export default HeloanHomeEquityLoan;
